@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply rounded bg-primary-500 px-5 py-3 font-medium text-white shadow transition duration-200 ease-in-out enabled:hover:bg-opacity-85 disabled:opacity-25;
  }

  .btn-secondary {
    @apply rounded border px-5 py-3 shadow transition duration-200 ease-in-out hover:bg-gray-100;
  }

  .card {
    @apply max-w-md overflow-hidden rounded bg-white p-8 shadow-md;
  }

  .input {
    @apply block rounded border-gray-300 py-1 shadow-sm focus:border-primary-500 focus:ring-primary-500;
  }

  .link {
    @apply cursor-pointer text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300;
  }
}

:root {
  /* This is used for the spinner and progress bar */
  --color-primary-500: #10b981;
}

@media (pointer: fine) {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-gray-100 dark:bg-gray-700;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-300 dark:bg-gray-500;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-400 dark:bg-gray-400;
  }
}
